<template>
	<div style="width: 1500px;height: 800px;margin:calc((100vh - 800px)/2) auto;display: flex;">
		<div style="width: 45%;height: 100%;margin: 0;padding: 0">
			<el-image :src="require('../../assets/imgs/index/reg.png')" style="width: 100%;height: 100%;"></el-image>
		</div>
		<div style="width: 55%;height: 100%;background: #fff;">
			<div style="width: 60%;height: 100px;margin:20px 20% 0 20%;display: flex;font-weight: bold">
				<el-button type="text" style="width: 50%;height: 100px;line-height: 100px;text-align: center;font-size: larger;font-weight: bold" @click="changeRegType(1)" :style="{color: form.regType===1?'#2E57EE':'#999999'}">个人账号注册</el-button>
				<el-button type="text" style="width: 50%;height: 100px;line-height: 100px;text-align: center;font-size: larger;font-weight: bold" @click="changeRegType(2)" :style="{color: form.regType===2?'#2E57EE':'#999999'}">机构账号注册</el-button>
			</div>
			<div style="width: 58%;height: calc(100% - 140px);margin:0 25% 0 15%;color: #000">
				<el-form v-if="form.regType===1" ref="form" status-icon :model="form"  :rules="rules" label-width="100px">
					<el-form-item label="昵称" prop="nickName">
						<el-input placeholder="请设置您的百工宝昵称" v-model.trim="form.nickName" :maxlength="10" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="登录账号" prop="accoun">
						<el-input placeholder="请设置用于登录百工宝的账号"  v-model.trim="form.accoun" :maxlength="20" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="账号密码" prop="pwd">
						<el-input placeholder="请设置您的账号密码" v-model.trim="form.pwd" autocomplete="new-password" show-password :maxlength="12"></el-input>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdLength?'#5BC130':'#999999'}">  包含8-12个字符  </span>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdNumber?'#5BC130':'#999999'}">  包含数字  </span>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdString?'#5BC130':'#999999'}">  包含字母  </span>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPwd">
						<el-input placeholder="请再次输入您的账号密码" v-model.trim="form.confirmPwd" show-password :maxlength="12"></el-input>
					</el-form-item>
					<el-form-item label="绑定手机号" prop="phone">
						<el-input placeholder="请输入11位手机号" v-model.number="form.phone" :maxlength="11"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<el-input placeholder="请输入验证码" v-model="form.code" :maxlength="6">
							<template slot="append">
								<el-button
									style="border-radius: 0 4px 4px 0;width: 120px;"
									:style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '35px' : '40px', }"
									:disabled="countdown>0"
									@click="sendVerificationCode"
								>
									{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
								</el-button>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item label="绑定邮箱" prop="email">
						<el-input placeholder="请输入正确的邮箱号" v-model.trim="form.email"></el-input>
					</el-form-item>
					<el-form-item label="邮箱验证码" prop="emailCode">
						<el-input placeholder="请输入邮箱收到的验证码" v-model="form.emailCode" :maxlength="6">
							<template slot="append">
								<el-button
									style="border-radius: 0 4px 4px 0;width: 150px;"
									:style="{ background: emailCountdown>0 ? '#F5F5F5' :'#1C7EFF', color: emailCountdown>0 ? '#666' : '#fff',height: emailCountdown>0 ? '35px' : '40px', }"
									:disabled="emailCountdown>0 || !form.email"
									@click="sendVerificationEmailCode"
								>
									{{ emailCountdown > 0 ? `${emailCountdown}秒后重试` : '获取邮箱验证码' }}
								</el-button>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button v-if="agreementStatus" style="width: 100%;background: #2E57EE;color: #fff" @click="reg()">注册账号</el-button>
                        <el-button v-else style="width: 100%; background: #ccc; color: #fff;border: 1px solid #DCDFE6;" disabled>注册账号</el-button>
					</el-form-item>
				</el-form>
				<el-form v-else ref="form" status-icon :model="form"  :rules="rules" label-width="100px">
					<el-form-item label="类别" prop="organizationType">
						<el-radio-group v-model="form.organizationType">
							<div style="width: 25%;display: flex;">
								<el-radio class="bordered" border :label="1">企业</el-radio>
								<el-radio class="bordered" border :label="2">机关事业</el-radio>
								<el-radio class="bordered" border :label="3">社团组织或团体</el-radio>
								<el-radio class="bordered" border :label="4">个体工商户</el-radio>
							</div>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="机构名称" prop="nickName">
						<el-input placeholder="请输入机构全程或者简称" v-model.trim="form.nickName" :maxlength="10" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="机构账号" prop="accoun">
						<el-input placeholder="请设置用于登录百工宝的账号" v-model.trim="form.accoun" :maxlength="10" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="账号密码" prop="pwd">
						<el-input placeholder="请设置您的账号密码" v-model.trim="form.pwd" show-password :maxlength="12"></el-input>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdLength?'#5BC130':'#999999'}">  包含8-12个字符  </span>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdNumber?'#5BC130':'#999999'}">  包含数字  </span>
						<span class="el-icon-warning-outline" style="margin-left: 20px;" :style="{color:pwdString?'#5BC130':'#999999'}">  包含字母  </span>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPwd">
						<el-input placeholder="请再次输入您的账号密码" v-model.trim="form.confirmPwd" show-password :maxlength="12"></el-input>
					</el-form-item>
					<el-form-item label="法人姓名" prop="full">
						<el-input placeholder="建议是机构法人本人注册" v-model.trim="form.full"></el-input>
					</el-form-item>
					<el-form-item label="绑定手机号" prop="phone">
						<el-input placeholder="请输入11位手机号" v-model.number="form.phone" :maxlength="11"></el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="code">
						<el-input placeholder="请输入验证码" v-model="form.code" :maxlength="6">
							<template slot="append">
								<el-button
									style="border-radius: 0 4px 4px 0;width: 120px;"
									:style="{ background: countdown>0 ? '#F5F5F5' : '#1C7EFF', color: countdown>0 ? '#666' : '#fff',height: countdown>0 ? '35px' : '40px', }"
									:disabled="countdown>0"
									@click="sendVerificationCode"
								>
									{{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
								</el-button>
							</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button v-if="agreementStatus" style="width: 100%;background: #2E57EE;color: #fff" @click="reg()">注册账号</el-button>
                        <el-button v-else style="width: 100%; background: #ccc; color: #fff;border: 1px solid #DCDFE6;" disabled>注册账号</el-button>
					</el-form-item>
				</el-form>
				<div style="width: 78%;height: 60px;margin:0 0 0 22%;display: flex">
                    <el-checkbox class="checkOverHidden" v-model="agreementStatus">
                       <div style="float: left">我已认真阅读并同意
                           <span @click="browse(1)">《百工宝注册协议》</span>
                           ，
                           <span @click="browse(2)">《百工宝使用协议》</span>
                           及
                           <span @click="browse(3)">《百工宝关于用户隐私协议》</span>
                           及
                           <span @click="browse(4)">《百工宝网络服务交易协议》</span>
                       </div>
                    </el-checkbox>
				</div>
			</div>
		</div>
	</div>

</template>


<script>
export default {
		components: {
        //  dialogComponent
		},
        data () {
	        return {
		        pwdLength:false,
		        pwdNumber:false,
		        pwdString:false,
		        countdown: 0,
		        emailCountdown: 0,
		        agreementStatus: false,
	            form: {
	              regType:1,
	              organizationType:0,
	              nickName: '',
	              accoun: "",
	              pwd: '',
	              confirmPwd:'',
	              phone: '',
	              code: '',
	              email: '',
	              emailCode: '',
	              full: '',
	              deviceTypes:1,
	            },

	            rules: {
	              nickName: [
	                { required: true, message: '请输入昵称', trigger: 'blur' },
	                { min: 1, max:10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
	              ],
	              accoun: [
	                { required: true, message: '请输入账号', trigger: 'blur' },
	                { min: 6, max:20, message: '长度在 6 到20 个字符', trigger: 'blur' }
	              ],
	              pwd: [
	                { required: true,trigger: 'blur',validator: this.validatePwd}
	              ],
	              confirmPwd:[
	                { required: true, message: '确认密码不能为空', trigger: 'blur' },
	                { trigger: 'blur',validator: this.validateConfirmPwd  }
	              ],
	              phone: [
	                { required: true, message: '手机号不能为空', trigger: 'blur' },
	                { pattern: /^\d{11}$/, message: '请输入正确的11位手机号', trigger: 'blur' }
	              ],
	              code: [
	                { required: true, message: '验证码不能为空', trigger: 'blur' },
	                {pattern: /^\d{6}$/, message: '请输入正确的6位验证码', trigger: 'blur' },
	              ],
	              email: [
	                { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
	              ],
	              emailCode: [
	                {pattern: /^\d{6}$/, message: '请输入正确的6位验证码', trigger: 'blur' },
	              ],
	              full: [
	                { required: true, message: '机构法人不能为空', trigger: 'blur' },
	                {pattern: /^[\u4e00-\u9fa5]+$/, message: '请输入正确的姓名', trigger: 'blur' },
	              ],
	              organizationType:[
	                { required: true, message: '请选择机构类别', trigger: 'change' }
	              ]
	            }
			}
		},
        created:function(){
			
		},
		mounted: function () {
			if (this.$route.query.type){
				this.form.regType = parseInt(this.$route.query.type);
			}
		},
        methods:{
			//切换注册类型
	        changeRegType(index){
		        this.form.regType = index;
		        this.$refs.form.clearValidate()
	        },
			//校验密码
	        validatePwd (rule, value, callback) {
		        if (value.length < 8 || value.length > 12) {
			        this.pwdLength = false;
			        callback(new Error('请输入8-12位密码'));
		        }else{
			        this.pwdLength = true;
		        }
		        if (!/^(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(value)) {
			        this.pwdString = false;
			        this.pwdNumber = false;
			        callback(new Error('密码必须包含数字和字母'));
		        }else{
			        this.pwdString = true;
			        this.pwdNumber = true;
		        }
				if (this.pwdString && this.pwdNumber && this.pwdLength) {
					//去检查确认密码
					this.$refs.form.validateField('confirmPwd');
				}
		        callback();
	        },
	        //确认密码
	        validateConfirmPwd (rule, value, callback){
		        if (value === '') {
			        callback(new Error('请再次输入密码'));
		        } else if (value !== this.form.pwd) {
			        callback(new Error('两次输入密码不一致!'));
		        } else {
			        callback();
		        }
	        },
	        //发送验证码
	        sendVerificationCode() {
				const that = this;
		        if (this.countdown > 0) return;
		        this.$refs.form.validateField('phone', (errorMsg) => {
			        if (!errorMsg) {
				        this.startCountdown();
				        this.newApi.sendSms({"mobile":that.form.phone, smsType: 5}).then((ret)=>{
					        that.utils.sus(ret.data);
				        }).catch((err)=>{
					        that.utils.err(err);
				        });
			        }
		        })
	        },
	        //倒计时
	        startCountdown() {
		        this.countdown = 60;
		        const timer = setInterval(() => {
			        this.countdown -= 1;
			        if (this.countdown <= 0) {
				        clearInterval(timer);
			        }
		        }, 1000);
	        },
	        //发送邮箱验证码
	        sendVerificationEmailCode() {
				const that = this;
		        if (this.emailCountdown > 0) return;
		        this.$refs.form.validateField('email', (errorMsg) => {
			        console.log(errorMsg);
			        if (!errorMsg) {
				        this.startEmailCountdown();
				        // sendRegisterEmail发送邮箱验证码
				        this.newApi.sendEmailCode({"email":that.form.email}).then((ret)=>{
					        that.utils.sus(ret.data);
				        }).catch((err)=>{
					        that.utils.err(err);
				        });
			        }
		        })
	        },
	        //倒计时
	        startEmailCountdown() {
		        this.emailCountdown = 60;
		        const emailTimer = setInterval(() => {
			        this.emailCountdown -= 1;
			        if (this.emailCountdown <= 0) {
				        clearInterval(emailTimer);
			        }
		        }, 1000);
	        },
	        reg(){
				const that = this;
		        this.$refs.form.validate((valid) => {
			        if (valid) {
						if (this.form.email !== "" && this.form.regType ===1){
							if (this.form.emailCode === ""){
								that.utils.err("邮箱验证码不能为空");
								return false;
							}
						}
				        if (this.form.organizationType === 0 && this.form.regType === 2){
					        that.utils.err("机构类别不能为空");
					        return false;
				        }
				        if (that.agreementStatus){
					        that.newApi.userRegister(this.form).then((ret)=>{
						        if (ret.isSuccess === 1){
							        that.utils.sus("注册成功");
                                    let param = {
                                        accoun: that.form.accoun,
                                        pwd: that.form.pwd
                                    }
                                    localStorage.token = "";
                                    localStorage.uuid = "";
                                    setTimeout(that.newApi.getLoginAccount(param).then((res)=>{
                                        if(res.isSuccess == 1){
                                            localStorage.token = res.data.token;
                                            localStorage.uuid = res.data.uuid;
                                            that.$store.commit("get_user_info");
                                            //记录登录时间
                                            that.newApi.updateLoginTime({uuid: res.data.uuid});
                                            //订阅信息
                                            that.getSubscribe(res.data.uuid);
                                            this.$router.push({ path: '/' });
                                        }
                                    }),500)


						        }
					        })
				        }else {
					        that.utils.err("请先同意用户协议");
				        }
			        } else {
				        return false;
			        }
		        });
	        },

            //获取订阅信息
            getSubscribe(uuid) {
                var that = this;
                that.newApi.getSubscribe({ type: 1, uuid: uuid }).then((res) => {
                    if (res.isSuccess == 1){
                        that.$store.state.subList = res.data.subList;
                    }
                })
            },
            browse(agreement) {
                let url;
                switch (agreement) {
                    case 1:
                        url = 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf';
                        break;
                    case 2:
                        url = 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E5%88%9B%E4%BD%9C%E8%80%85%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf';
                        break;
                    case 3:
                        url = 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%8D%8F%E8%AE%AE.pdf';
                        break;
                    case 4:
                        url = 'https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/agreement/registrationAgreement/%E7%99%BE%E5%B7%A5%E5%AE%9D%E5%B9%B3%E5%8F%B0%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%8D%8F%E8%AE%AE.pdf';
                        break;
                    default:
                        break;
                }
                if (url) {
                    window.open(url, '_blank');
                }
            }
		}
}

</script>
<style scoped>
.checkOverHidden /deep/ .el-checkbox__label{
	display: inline-grid!important;
	white-space: pre-line!important;
	word-wrap: break-word!important;
	overflow: hidden!important;
	padding-right: 10px!important;
	font-size: 12px;
}
.checkOverHidden /deep/ b{
	display: inline-block;
	min-width: 53px;
}

.bordered{
	margin-right: -5px;
}
.centered {
  position: absolute; /* 绝对定位 */
  top: 42%; /* 根据容器高度计算，将子 div 放置在容器垂直中心位置 */
  left: 50%; /* 根据容器宽度计算，将子 div 放置在容器水平中心位置 */
  transform: translate(-50%, -50%); /* 利用 transform 属性将子 div 自身的尺寸也考虑在内，实现完全居中 */
  padding: 20px; /* 子 div 内边距 */
  text-align: center; /* 文本居中显示 */
}
</style>
